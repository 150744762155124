import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IDropdownOptions, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3DropdownField } from '../common/DR3DropdownField';

// constants
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const DealScreenExperienceToggle = value;

        updateDealerCallback(null, {
            ...oldDealer,
            dealScreenExperience: DealScreenExperienceToggle
        });
    };
}

const defaultOptions: IDropdownOptions[] = [
    {
        optionValue: 'dealCentral',
        optionLabel: 'Deal Central'
    },
    {
        optionValue: 'base',
        optionLabel: 'Base'
    }
];

const DealScreenExperience: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = props.dealer.dealScreenExperience || 'dealCentral';
    const tooltip = 'This feature allows to identify the deal screen variation for a dealer: deal central or base.';

    return (
        <DR3DropdownField
            label={ToggleLabelMap.dealScreenExperience}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            options={defaultOptions}
            tooltip={tooltip}
        />
    );
};
export default DealScreenExperience;
