import React, { FC, useCallback } from 'react';
import { DR3CheckboxGroup } from '../../../common/DR3CheckboxGroup';
import { DR3CheckboxField } from '../../../common/DR3CheckboxField';
import { DR3DropdownField } from '../../../common/DR3DropdownField';
import { IDealerToManage, IDropdownOptions } from '../../../../store/manager/types';
import { RootState } from '../../../../store';
import { connect } from 'react-redux';
import { FieldsInDealerToUpdate } from '../../AMDTradeInMethodsContainer';
import { DR3RadioBooleanField } from '../../../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../../../constants/ToggleConstants';

type CheckBoxStatuses = {
    kbbGuide: boolean;
    kbbIco: boolean;
    dealerAppraisal: boolean;
};

type SelectedMethod = keyof CheckBoxStatuses;

interface TradeinMethodsCheckboxGroupProps {
    selectedMethods?: CheckBoxStatuses;
    defaultMethod?: SelectedMethod;
    fieldsToUpdate: FieldsInDealerToUpdate;
    dealer: IDealerToManage | null;
    showAppraisalValueScreen?: boolean;
    handleUpdateAvailableMethods: (dealer: IDealerToManage | null, values: any) => IDealerToManage | null;
    handleUpdateDefaultMethod: (dealer: IDealerToManage | null, value: any) => void;
    handleUpdateAppraisalValueScreen?: (dealer: IDealerToManage | null, value: boolean) => void;
}

const TradeinMethodsCheckboxGroup: FC<TradeinMethodsCheckboxGroupProps> = (props) => {
    const {
        dealer,
        handleUpdateAvailableMethods,
        handleUpdateDefaultMethod,
        fieldsToUpdate,
        showAppraisalValueScreen = false,
        handleUpdateAppraisalValueScreen
    } = props;

    const defaultMethod = (dealer?.[fieldsToUpdate.defaultMethod] as SelectedMethod) || '';
    const selectedMethods: CheckBoxStatuses = {
        kbbIco: (dealer?.[fieldsToUpdate.kbbIco] as boolean) || false,
        kbbGuide: (dealer?.[fieldsToUpdate.kbbGuide] as boolean) || false,
        dealerAppraisal: (dealer?.[fieldsToUpdate.dealerAppraisal] as boolean) || false
    };

    const labels: { [key in SelectedMethod]: string } = {
        kbbGuide: 'KBB Guide',
        kbbIco: 'KBB ICO',
        dealerAppraisal: 'Dealer Appraisal'
    };

    const getDropdownOptions = useCallback(() => {
        const options: IDropdownOptions[] = [];
        if (selectedMethods.dealerAppraisal) {
            options.push({ optionLabel: labels.dealerAppraisal, optionValue: 'dealerAppraisal' });
        }

        if (selectedMethods.kbbIco) {
            options.push({ optionLabel: labels.kbbIco, optionValue: 'kbbIco' });
        }

        if (selectedMethods.kbbGuide) {
            options.push({ optionLabel: labels.kbbGuide, optionValue: 'kbbGuide' });
        }

        return options;
    }, [selectedMethods]);

    const handleChange = (value: keyof CheckBoxStatuses) => (checked: boolean) => {
        if (!dealer) return;
        const checkedCheckboxes = { ...selectedMethods, [value]: checked };
        const updatedDealer = handleUpdateAvailableMethods(dealer, checkedCheckboxes);

        if (checkedCheckboxes.kbbIco) {
            handleUpdateDefaultMethod(updatedDealer, 'kbbIco');
        }

        if (checkedCheckboxes.kbbGuide) {
            handleUpdateDefaultMethod(updatedDealer, 'kbbGuide');
        }

        if (checkedCheckboxes.dealerAppraisal) {
            handleUpdateDefaultMethod(updatedDealer, 'dealerAppraisal');
        }
    };

    const handleAppraisalValueScreen = (value: boolean) => {
        handleUpdateAppraisalValueScreen?.(dealer, value);
    };

    return (
        <>
            <DR3CheckboxGroup>
                <DR3CheckboxField
                    checked={!!dealer?.[fieldsToUpdate.kbbGuide]}
                    data-testid="kbbGuide-checkbox"
                    label="KBB Guide"
                    handleChange={handleChange('kbbGuide')}
                />
                <DR3CheckboxField
                    checked={!!dealer?.[fieldsToUpdate.kbbIco]}
                    data-testid="kbbIco-checkbox"
                    label="KBB ICO"
                    handleChange={handleChange('kbbIco')}
                />
                <DR3CheckboxField
                    checked={!!dealer?.[fieldsToUpdate.dealerAppraisal]}
                    data-testid="dealerAppraisal-checkbox"
                    label="Dealer Appraisal"
                    handleChange={handleChange('dealerAppraisal')}
                />
            </DR3CheckboxGroup>
            <DR3DropdownField
                data-testid="default-method-dropdown"
                label="Default method"
                value={defaultMethod || ''}
                handleChange={(value) => handleUpdateDefaultMethod(dealer, value)}
                options={getDropdownOptions()}
            />
            {showAppraisalValueScreen && !!dealer?.[fieldsToUpdate.dealerAppraisal] && (
                <DR3RadioBooleanField
                    label={ToggleLabelMap.showAppraisalValueScreenSalesView}
                    value={dealer?.showAppraisalValueScreenSalesView || false}
                    trueOption="Yes"
                    falseOption="No"
                    handleChange={handleAppraisalValueScreen}
                    tooltipProps={ToggleLabelMap.showAppraisalSalesViewTooltip}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        dealer: state.manager.dealerToManage || null
    };
};

export default connect(mapStateToProps)(TradeinMethodsCheckboxGroup);
