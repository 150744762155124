import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const showPaymentRange = value;
        let showFlexiblePaymentOptionsAMD: boolean = oldDealer.showFlexiblePaymentOptionsAMD;
        let showFlexiblePaymentOptionsSalesView: boolean = oldDealer.showFlexiblePaymentOptionsSalesView;

        if (showPaymentRange) {
            showFlexiblePaymentOptionsAMD = false;
            showFlexiblePaymentOptionsSalesView = false;
        }

        const newDealer = {
            ...oldDealer,
            showPaymentRange,
            showFlexiblePaymentOptionsAMD,
            showFlexiblePaymentOptionsSalesView
        };
        updateDealerCallback(null, newDealer);
    };
}

const tooltip =
    // eslint-disable-next-line max-len
    'This feature allows the user to independently show a payment range and hide APR for finance and lease deals in Sales View. When this feature is enabled, flexible payment options will be disabled.';

const PaymentRange: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = props.dealer.showPaymentRange;
    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.paymentRangeLabel}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default PaymentRange;
