import React, { FunctionComponent } from 'react';
import { DealXGClientMap, IClientContainerProps } from '../types';

import { DR3DropdownField } from '../../common/DR3DropdownField';
import { IDropdownOptions } from '../../../store/manager/types';

export function handleChange(oldClient: DealXGClientMap, updateClientCallback: React.Dispatch<React.SetStateAction<any>>) {
    return (value: string): void => {
        const newClient = {
            ...oldClient,
            obfuscationRuleName: value
        } as DealXGClientMap;

        if (value === '') delete newClient.obfuscationRuleName;
        updateClientCallback(newClient);
    };
}

const options: IDropdownOptions[] = [
    {
        optionValue: '',
        optionLabel: 'None'
    },
    {
        optionValue: 'offshore',
        optionLabel: 'offshore'
    },
    {
        optionValue: 'sensitivePersonalData',
        optionLabel: 'sensitive personal data'
    }
];

const RuleList: FunctionComponent<IClientContainerProps> = (props) => {
    const value = (props.client?.obfuscationRuleName && props.client.obfuscationRuleName) || '';
    return (
        <DR3DropdownField
            label="Rule Type"
            value={value || ''}
            handleChange={handleChange(props.client, props.setClient)}
            options={options}
        />
    );
};
export default RuleList;
