import { DealXGClientMap, defaultAlert, errorAlert } from './types';
import { saveDealXGClient, clientCleanUp, isMissingRequiredFields } from './utils';

export const saveClient = async (
    accessToken: string | null,
    setShowAlert: React.Dispatch<React.SetStateAction<any>>,
    setClient: React.Dispatch<React.SetStateAction<any>>,
    client: DealXGClientMap,
    isNew: boolean,
    setIsNew: React.Dispatch<React.SetStateAction<any>>
) => {
    if (accessToken) {
        const clientToSend = clientCleanUp(client);
        const missingFields = isMissingRequiredFields(clientToSend);
        if (missingFields.length) {
            const alert = { ...errorAlert, message: missingFields };
            setShowAlert(alert);
        } else {
            const result = await saveDealXGClient(accessToken, clientToSend, isNew);
            if (result.ok) {
                const alert = { ...defaultAlert, show: true };
                setShowAlert(alert);
                setClient(result.data as DealXGClientMap);
                setIsNew(false);
            } else {
                const alert = { ...errorAlert, message: result.error.message };
                setShowAlert(alert);
            }
        }
    }
};
