import { FeatureToggles } from '@makemydeal/dr-feature-toggles-client';
import { IUploadBrandingExpRequest } from '../../utils/Api';

export interface IManagerState {
    dealerToManage: IDealerToManage | null;
    fetchSearchedDealersalers: IDealerToManage[] | null;
    messages: IManagerActivityMessage[];
    isSaving: boolean;
    isLoading: boolean;
    validatorMap: IValidatorMap;
    partnerMappings: PartnerMapping[];
}

export interface IGetDealerResponse {
    _id: any;
    accessories?: IDealerAccessories;
    accountStatus?: string;
    address?: IDealerAddress;
    applyTaxesAndFees?: boolean;
    applyTaxesAndFeesNewOptions?: string;
    brandingExperience?: string;
    canNegotiateNew?: boolean;
    canNegotiateUsed?: boolean;
    cash?: IDealerCash;
    commonOrgId?: string;
    contactOptions?: IDealerContactOptions;
    creditApp?: IDealerCreditApp;
    creditApplicationButtonText?: string;
    creditApplicationURL?: string;
    creditProvider?: string;
    creditTiers?: IDealerCreditTier[];
    crmIntegration: IDealerCRMIntegration;
    dealerId: number;
    dealerSiteToggles?: IDealerSiteToggles;
    dealerTrackID?: number;
    defaultDealType?: string;
    dmsIntegration?: string;
    dnaAccountId?: string;
    ecommerceCTACopy?: string;
    ecommercePartner?: string;
    ecommerceURL?: string;
    enableAdminRedesignUI?: boolean;
    enableDarwin: boolean;
    enableDealerShowroomExperience?: boolean;
    enableGhostVin: boolean;
    enableItemizedManufacturerIncentives?: boolean;
    enableManualTradeDecision?: boolean;
    enableOccIntegration?: boolean;
    enablePrePurchaseAgreement?: boolean;
    enableSwitchVehicle?: boolean;
    enableTradeDecisionPage?: boolean;
    enableVauto?: boolean;
    esntialPartnerDealerId?: number;
    esntialPartnerId?: string;
    excludeStdCashIncsCash?: boolean;
    excludeStdCashIncsLease?: boolean;
    excludeStdCashIncsLoan?: boolean;
    financing?: IDealerFinancing;
    hasCreditDecision?: boolean;
    howItWorksPosterUrl?: string;
    howItWorksVideoUrl?: string;
    incentives?: IDealerIncentives;
    isAccelerateCreditAppEnabled: boolean;
    isConsumerFacingCreditDecisionEnabled: boolean;
    consumerFacingCreditDecisionType?: string;
    isAccelerateCreditDecisionEnabled: boolean;
    isCCPAEnabled?: boolean;
    isContinueToDeskingDisabled?: boolean;
    enableCheckoutAnywhereInMV?: boolean;
    enableCheckoutAnywhereInSV?: boolean;
    isDealertrackCreditProvider?: boolean;
    isDIOEnabled?: boolean;
    isDocUploadEnabled: boolean;
    isMenuFitToHeightEnabled?: boolean;
    isMenuPricingBasedOn?: string;
    isMenuPricingDisabled?: boolean;
    isPaymentDriverPreferred?: boolean;
    isPaymentDriverPreferredForCashDeals?: boolean;
    isPhoneNumberRequired: boolean;
    isPhoneRequiredForConsentDisplay: boolean;
    isRedesignEnabled: boolean;
    isTargetPlatformR1J: boolean;
    isTCPACheckboxDisabled?: boolean;
    isTCPAEnabled?: boolean;
    isTestDealer?: boolean;
    isTestDriveAvailableSunday?: boolean;
    isTradeInBranded?: boolean;
    isVehicleDeliveryEnabled?: boolean;
    isWidgetHeightDynamic?: boolean;
    labelSetName?: string;
    leadGatePaymentOnDS?: boolean;
    leadRoutingOverrideSettings?: IDealerLeadRoutingOverrideSettings;
    leasing?: IDealerLeasing;
    listingOverride?: IListingOverride;
    locationToSendLeadsNew?: string;
    locationToSendLeadsUsed?: string;
    longCustomDisclaimer?: string;
    name?: string;
    overrideDRSAccountId?: string;
    overrideDRSActive?: boolean;
    ownerId?: number;
    partnerId?: string;
    phone?: string;
    printOfferShowSignatureLine: boolean;
    showPrintItemizedDealBreakdown: boolean;
    privacyNoticeUrl?: string;
    products?: IDealerProducts;
    productSet: IDealerProductSet;
    reservation?: IDealerReservation;
    reviewDealUrlEnabled?: boolean;
    routeOnePartyId?: string;
    sdpModalZIndex?: number;
    shopperEducationVideoURL?: string;
    shortCustomDisclaimer?: string;
    showAppraisalValueScreenSalesView: boolean;
    showCreditApplicationDisclaimer?: boolean;
    showDealPulseInManagerView?: boolean;
    showFlexiblePaymentOptionsAMD?: boolean;
    showFlexiblePaymentOptionsSalesView?: boolean;
    sponsorSetting?: string;
    TCPAConsentText?: string;
    TCPAProvider?: string;
    testDriveServiceProvider?: string;
    theme?: string;
    themeRedesign?: string;
    tradeInHideExcellent?: boolean;
    tradeInValuationICOProviderUrl?: string;
    tradeInValuationLeadForm?: boolean;
    tradeInValuationProvider?: string;
    tradeInValuationProviderDefault: string | null;
    tradeInValuationProviderDefaultSalesView: string | null;
    tradeInValuationProviderKBB: boolean;
    tradeInValuationProviderKBBICO: boolean;
    tradeInValuationProviderKBBICOSalesView: boolean;
    tradeInValuationProviderKBBSalesView: boolean;
    tradeInValuationProviderManual: boolean;
    tradeInValuationProviderManualSalesView: boolean;
    updatedBy?: string;
    updatedOn?: string;
    useFnI2?: boolean;
    useZipCodeService?: boolean;
    website?: string;
    dealScreenExperience?: string;
    showPaymentRange?: boolean;
    financeRangeAmount?: number;
    leaseRangeAmount?: number;
}

export type ICloneDealerResponse = IGetDealerResponse;

export interface IGetSearchedDealersReponse {
    page: number;
    pageCount: number;
    count: number;
    hasMore: false;
    data: IDealerToManage[];
}
export interface ISaveDealerResponse {
    statusCode: number;
    error: string;
    message: string;
    dealerId: string;
}
export interface ICreateDealerResponse {
    _id: any;
    dealerId: number;
}

export interface IUploadBrandingExpResponse {
    Bucket: string;
    Key: string;
    Location: string;
}

interface IFieldValidationError {
    message: string;
}
interface IManagerActivityMessage {
    level: 'info' | 'warn' | 'error';
    content: string;
    timestamp: number;
}

export interface IDropdownOptions {
    optionValue: string;
    optionLabel: string;
}

export interface INumberDropdownOptions {
    optionValue: number;
    optionLabel: string;
}

export interface IInputAdornmentProps {
    position: 'start' | 'end';
    unit: string;
}

export interface IDealerAddress {
    street: string;
    city: string;
    state: string;
    zip: string;
    address2: string;
}

export interface IDealerContactOptions {
    trackingPhone: string;
    isChatEnabled: boolean;
    isPhoneEnabled: boolean;
    isSmsEnabled: boolean;
    smsPhone: string;
    isChatUpgradeEnabled: boolean;
    livePersonAccountId: string;
    isActivEngageEnabled: boolean;
    activEngageMmdId: string;
}

export interface IDealerProducts {
    hasFi: boolean;
    hasAccessories: boolean;
    hasLeasing: boolean;
    hasNewCashOffer: boolean;
    hasShowroom: boolean;
    hasTestDrive: boolean;
    hasTestDriveAtHome: boolean;
    hasUsedCashOffer: boolean;
    hasWidgetNew: boolean;
    hasWidgetUsed: boolean;
    isAccessoriesCatalogEnabled: boolean;
    isSubaruGtpEnabled: boolean;
}

export interface IDealerAccessories {
    hideMenuPricing: boolean;
    laborRate: number;
}

export interface IListingOverrideProducts {
    hasWidgetNew: boolean;
    hasWidgetUsed: boolean;
    hasFi: boolean;
    hasUsedCashOffer: boolean;
    hasNewCashOffer: boolean;
}

export interface IDealerCreditApp {
    shouldSendCreditDecisionEmail: boolean;
    isSoftPullEnabled: boolean;
    isEmployerAddressRequired: boolean;
    enableLeadFormBeforeCreditApp: boolean;
}

export interface IDealerIncentives {
    includeIncentivesInPrice: boolean;
    excludeSpecialAPRLoanOffersNewCar: boolean;
    excludeSpecialAPRLoanOffersUsedCar: boolean;
    excludeSpecialAPRLeaseOffersNewCar: boolean;
    enableMaxCashIncentives: boolean;
}

export interface IDealerReservation {
    showReservationDetail: boolean;
    accountToken: string;
    reservationType: string;
    depositType: string;
    accountId: string;
    terminalId: string;
    acceptorId: string;
    amount: number;
    holdPeriod: number;
}

export interface IDealerSiteToggles {
    isDefaultHeaderTextEnabled: boolean;
    isVDPLeadFormEnabled: boolean;
}

export interface IListingOverrideSiteToggles {
    isVDPLeadFormEnabled: boolean;
}

export interface IDealerFinancing {
    isDownPaymentFixedAmount: boolean;
    downPaymentPercent: number;
    downPaymentPercentUsed: number;
    downPaymentFixedAmountNew: number;
    downPaymentFixedAmountUsed: number;
    limits: IDealerLimits;
    defaultTermNew: number;
    defaultTermUsed: number;
}

export interface IDealerLeasing {
    allowMultipleSecurityDepositPayments: boolean;
    enableUnifyDealSummary: boolean;
    calculateOnMSRP: boolean;
    isDueAtSigninAmountDeduct: boolean;
    isDownPaymentFixedAmount: boolean;
    downPaymentFixedAmount: number;
    downPaymentPercent: number;
    isMonthlyPaymentDueAtSigning: boolean;
    terms: IDealerTerms[];
    mileages: IDealerMileages[];
}

export interface IDealerCash {
    calculateOnMSRP: boolean;
}

export interface IDealerLeadRoutingOverrideSettings {
    leadRoutingOverrideEnabled: boolean;
    dealertrackOverrideId?: number;
}

export interface IDealerLimits {
    mileage: number;
    price: number;
    year: number;
}

export interface IDealerCreditTier {
    isDefault: boolean;
    _id: string;
    type: string;
    name: string;
    lowScore: number;
    highScore: number;
    legacyId: number;
    aprs: IDealerCreditTierAPR[];
}

export interface IDealerCreditTierAPR {
    isDefault: boolean;
    _id: string;
    rate: number;
    term: number;
}

export interface IDealerTerms {
    isDefault: boolean;
    months: number;
    _id: string;
}

export interface IDealerMileages {
    isBase: boolean;
    isDefault: boolean;
    mileage: number;
    _id: string;
}

interface IDealerCRMIntegration {
    vinSolutionsCrmToggle: string;
    vinSolutionsAccountId?: number;
}

export interface IDealerProductSet {
    accelerateMyDeal: boolean;
    standaloneCreditApp: boolean;
    ecommerce: boolean;
}

export interface IDealerToManage {
    _id: any;
    accessories: IDealerAccessories;
    accountStatus: string;
    address: IDealerAddress;
    applyTaxesAndFees: boolean;
    applyTaxesAndFeesNewOptions: string;
    areDealerSettingOverridesEnabled?: boolean;
    brandingExperience: string;
    canNegotiateNew: boolean;
    canNegotiateUsed: boolean;
    cash: IDealerCash;
    commonOrgId: string;
    contactOptions: IDealerContactOptions;
    creditApp: IDealerCreditApp;
    creditApplicationButtonText: string;
    creditApplicationURL: string;
    creditProvider: string;
    creditTiers: IDealerCreditTier[];
    crmIntegration: IDealerCRMIntegration;
    dealerId: number;
    dealerSiteToggles: IDealerSiteToggles;
    dealerTrackID: number;
    defaultDealType: string;
    dmsIntegration: string;
    dnaAccountId: string;
    ecommerceCTACopy: string;
    ecommercePartner: string;
    ecommerceURL: string;
    enableAdminRedesignUI: boolean;
    enableDarwin: boolean;
    enableDealerShowroomExperience?: boolean;
    enableGhostVin: boolean;
    enableItemizedManufacturerIncentives?: boolean;
    enableManualTradeDecision: boolean;
    enableOccIntegration: boolean;
    enablePrePurchaseAgreement: boolean;
    enableSwitchVehicle: boolean;
    enableTradeDecisionPage: boolean;
    enableVauto: boolean;
    esntialPartnerDealerId: number;
    esntialPartnerId: string;
    excludeStdCashIncsCash: boolean;
    excludeStdCashIncsLease: boolean;
    excludeStdCashIncsLoan: boolean;
    financing: IDealerFinancing;
    hasCreditDecision: boolean;
    howItWorksPosterUrl: string;
    howItWorksVideoUrl: string;
    incentives: IDealerIncentives;
    isAccelerateCreditAppEnabled: boolean;
    isConsumerFacingCreditDecisionEnabled: boolean;
    consumerFacingCreditDecisionType: string;
    isAccelerateCreditDecisionEnabled: boolean;
    isCCPAEnabled: boolean;
    isContinueToDeskingDisabled: boolean;
    enableCheckoutAnywhereInMV: boolean;
    enableCheckoutAnywhereInSV: boolean;
    isDealertrackCreditProvider?: boolean;
    isDIOEnabled?: boolean;
    isDocUploadEnabled: boolean;
    isMenuFitToHeightEnabled: boolean;
    isMenuPricingBasedOn: string;
    isMenuPricingDisabled: boolean;
    isPaymentDriverPreferred: boolean;
    isPaymentDriverPreferredForCashDeals: boolean;
    isPhoneNumberRequired: boolean;
    isPhoneRequiredForConsentDisplay: boolean;
    isRedesignEnabled: boolean;
    isTargetPlatformR1J: boolean;
    isTCPACheckboxDisabled: boolean;
    isTCPAEnabled: boolean;
    isTestDealer: boolean;
    isTestDriveAvailableSunday: boolean;
    isTradeInBranded: boolean;
    isVehicleDeliveryEnabled?: boolean;
    isWidgetHeightDynamic: boolean;
    labelSetName: string;
    leadGatePaymentOnDS: boolean;
    leadRoutingOverrideSettings: IDealerLeadRoutingOverrideSettings;
    leasing: IDealerLeasing;
    listingOverride: IListingOverride;
    locationToSendLeadsNew: string;
    locationToSendLeadsUsed: string;
    longCustomDisclaimer: string;
    name: string;
    overrideDRSAccountId: string;
    overrideDRSActive: boolean;
    ownerId: number;
    partnerId: string;
    partnerMappings?: NewPartnerMapping[];
    phone: string;
    printOfferShowSignatureLine: boolean;
    showPrintItemizedDealBreakdown: boolean;
    privacyNoticeUrl: string;
    products: IDealerProducts;
    productSet: IDealerProductSet;
    reservation: IDealerReservation;
    reviewDealUrlEnabled: boolean;
    routeOnePartyId: string;
    sdpModalZIndex: number;
    shopperEducationVideoURL: string;
    shortCustomDisclaimer: string;
    showAppraisalValueScreenSalesView: boolean;
    showCreditApplicationDisclaimer: boolean;
    showDealPulseInManagerView: boolean;
    showFlexiblePaymentOptionsAMD: boolean;
    showFlexiblePaymentOptionsSalesView: boolean;
    sponsorSetting: string;
    TCPAConsentText: string;
    TCPAProvider: string;
    testDriveServiceProvider: string;
    theme: string;
    themeRedesign: string;
    tradeInHideExcellent: boolean;
    tradeInValuationICOProviderUrl: string;
    tradeInValuationLeadForm: boolean;
    tradeInValuationProvider: string;
    tradeInValuationProviderDefault: string | null;
    tradeInValuationProviderDefaultSalesView: string | null;
    tradeInValuationProviderKBB: boolean;
    tradeInValuationProviderKBBICO: boolean;
    tradeInValuationProviderKBBICOSalesView: boolean;
    tradeInValuationProviderKBBSalesView: boolean;
    tradeInValuationProviderManual: boolean;
    tradeInValuationProviderManualSalesView: boolean;
    updatedBy: string;
    updatedOn: string;
    useFnI2: boolean;
    useZipCodeService: boolean;
    vinSolutionsAccountId?: string;
    website: string;
    dealScreenExperience: string;
    showPaymentRange: boolean;
    financeRangeAmount: number;
    leaseRangeAmount: number;
}

export interface IListingOverride {
    products: IListingOverrideProducts;
    // New Car Widget Active on Dealer Site - hasWidgetNew
    // Used Car Widget Active on Dealer Site - hasWidgetUsed
    // Is vehicle protection active - hasFI
    tradeInValuationProvider: string;
    // Trade Service Provider
    tradeInValuationICOProviderUrl: string;
    // KBB ICO URL
    isTradeInBranded: boolean;
    // Vehicle Trade-In Button
    tradeInValuationLeadForm: boolean;
    // Show Leader Form Before Valuation
    canNegotiateNew: boolean;
    // Negotiation Type: New
    canNegotiateUsed: boolean;
    // Negotiation Type: Used
    dealerSiteToggles: IListingOverrideSiteToggles;
    // Is Lead Form Enabled on Vehicle Detail Page - isVDPLeadFormEnabled
    incentives: IDealerIncentives;
    // Non-conditional Incentives Included in Price - includeIncentivesInPrice
    // Enable Max Cash Incentives - enableMaxCashIncentives
    excludeStdCashIncsLoan: boolean;
    // Exclude Cash Offer from Loan Price
    excludeStdCashIncsLease: boolean;
    // Exclude Cash Offer from Cash Price
    excludeStdCashIncsCash: boolean;
    // Exclude Cash Offer from Lease Price
    contactOptions: IDealerContactOptions;
    // Contact At Once - isChatEnabled
    // isMessageEnabled
    // Phone Tracking Feature - isPhoneEnabled
    // Tracking Phone Number - trackingPone
    // SMS Feature - isSMSEnabled
    // SMS Phone Number - SMSPhone
    // Activ Engage - isActivEngageEnabled
    enablePrePurchaseAgreement: boolean;
    // Purchase Agreement Enabled
    reservation: IDealerReservation;
    // Enable Reservations
    isMenuPricingBasedOn: string;
    // Menu Pricing Type
    reviewDealUrlEnabled: boolean;
    // Enables sending the Shopper's URL to Dealer CRM
    isRedesignEnabled: boolean;
    isDIOEnabled: boolean;
    // Enables Dealer Installed Options
    tradeInHideExcellent: boolean;
    // KBB Hide Excellent
    leadGatePaymentOnDS: boolean;
    // Lead gate seeing the payment on DS (For Hendricks US1158669)
}

export interface IImageUploadDimensions {
    width: number;
    height: number;
}

export enum ManagerActionTypes {
    UPDATE_DEALER_TO_MANAGE,
    FETCH_ALL_DEALERS,
    FETCH_DEALER_TO_MANAGE,
    UPDATE_VALIDATOR,
    SAVE_DEALER,
    DEALER_PAGE_TAB_CHANGE,
    UPDATE_PARTNER_MAPPINGS
}
export interface IUpdateValidatorAction {
    type: ManagerActionTypes.UPDATE_VALIDATOR;
    payload: IValidator;
}
export interface IUpdateDealerAction {
    type: ManagerActionTypes.UPDATE_DEALER_TO_MANAGE;
    payload: IDealerToManage;
}
export interface IFetchSearchedDealersAction {
    type: ManagerActionTypes.FETCH_ALL_DEALERS;
    payload: IDealerToManage[];
}
export interface IFetchDealerAction {
    type: ManagerActionTypes.FETCH_DEALER_TO_MANAGE;
    payload: number;
}
export interface ISaveDealerAction {
    type: ManagerActionTypes.SAVE_DEALER;
    payload: IDealerToManage;
}
export interface IPartnerMappingsAction {
    type: ManagerActionTypes.UPDATE_PARTNER_MAPPINGS;
    payload: PartnerMapping[];
}
export interface IValidator {
    key: string;
    validation: null | {
        level: 'error';
        message?: string;
        type: 'missing' | 'invalid';
    };
}
export interface IValidatorMap {
    [key: string]: IValidator;
}

export interface ICommonManagerFieldProps {
    featureToggles?: FeatureToggles;
    dealer: IDealerToManage | null;
    validatorMap: IValidatorMap;
    updateDealerCallback: IUpdateDealerCallbackFn;
    validateCallback?: IValidateCallbackFn;
    saveDealerCallback: ISaveDealerCallbackFn;
    uploadBrandingExpCallback: IUploadBrandingExpCallbackFn;
    admin: boolean;
}

export type ManagerActionPayload =
    | IUpdateDealerAction
    | IFetchDealerAction
    | IUpdateValidatorAction
    | ISaveDealerAction
    | IFetchSearchedDealersAction
    | IPartnerMappingsAction;

export type IUpdateDealerCallbackFn = (err: string | null, newDealer?: IDealerToManage) => void;
export type IUpdateSearchedDealersCallbackFn = (err: string | null, newDealers?: IDealerToManage[]) => void;
export type IValidateCallbackFn = (validator: IValidator) => void;
// tslint:disable:variable-name
export type ISaveDealerCallbackFn = (err: string | null, Dealer: IDealerToManage) => void;
export type ICloneDealerCallbackFn = (Dealer: IDealerToManage) => Promise<void>;
export type ILoadSearchedDealersFn = (accessToken: string, urlQueryAddition: string) => Promise<void>;
export type IUploadBrandingExpCallbackFn = (
    err: string | null,
    dealer: IUploadBrandingExpRequest,
    imageList: FileList
) => Promise<IUploadBrandingExpResponse>;
export type IHandleFieldChange<T> = (value: T, value2?: T) => void;
export type IHandleUpdateFieldChange<T> = (value: T, value2: T) => void;
export type IUpdatePartnerMappingsCallbackFn = (err: string | null, PartnerMappings?: PartnerMapping[]) => void;

export enum TabSelected {
    GLOBAL,
    DEALER,
    LISTING,
    PARTNER_MAP,
    OLDDEALER
}

export enum SEARCH_TAB_LABELS {
    DEALER_SEARCH,
    DEALER_METRICS,
    UTILITIES,
    DEALXG_CLIENT_SETUP
}

export interface PartnerMapping {
    integratorId: string;
    partnerDealerId: string;
    status: 'original' | 'new' | 'deleted';
    isEditable?: boolean;
}

export interface IdPartnerMapping extends PartnerMapping {
    fieldToSave: keyof IDealerToManage;
    type: 'numeric' | 'string';
    quickLinks?: { linkName: string; linkValue: (s: string) => string }[];
    validate?: (rowData: IdPartnerMapping) => boolean | string;
}

export interface Integrator {
    integratorId: string;
    integratorName: string;
}

export type NewPartnerMapping = {
    name: string;
    value: string;
};
